<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../../../assets/img/home/banner.png" alt="" />
    </div>
    <!-- <div class="breadcrumb_box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>支付服务</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->
    <div class="container container_bg">
      <div class="box">
        <div class="container_title">支付产品———POS</div>
        <div class="card_box">
          <div class="card_list">
            <div class="card_item">
              <div class="top">
                <img src="../../../assets/img/home/detail/pos1.png" alt="" />
              </div>
              <div class="center">
                <span>传统POS</span>
                <span>传统POS机，支持2G\3G\4G\5G多种网络频段， </span>
                <span>支持刷卡、插卡、挥卡、动态码生成， </span>
                <span>
                  支付成功打印高清小票，可与开票系统打通，生成开票码。
                </span>
              </div>
            </div>
            <div class="card_item">
              <div class="top">
                <img src="../../../assets/img/home/detail/pos2.png" alt="" />
              </div>
              <div class="center">
                <span>智能POS</span>
                <span>智能POS机，搭载新版本安卓系统， </span>
                <span>可与业务系统对接，在POS中完成订单支付，</span>
                <span> 支持刷卡、插卡、挥卡、扫码等多种收款形式。 </span>
              </div>
            </div>
            <div class="card_item">
              <div class="top">
                <img src="../../../assets/img/home/detail/pos3.png" alt="" />
              </div>
              <div class="center">
                <span>电签POS</span>
                <span>电签POS机，小巧、便捷，方便随身携带， </span>
                <span>满足随时随地刷卡收款需求。</span>
              </div>
            </div>
          </div>
          <div class="btn" @click="handleApply(7)">立即咨询</div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="box">
        <div class="container_title">支付产品———聚合码</div>
        <div class="card_box">
          <div class="card_list">
            <div class="card_item card_bg">
              <div class="top">
                <img src="../../../assets/img/home/detail/jhm1.png" alt="" />
              </div>
              <div class="center">
                <span>静态码</span>
                <span>静态码牌，支持张贴在任一位置，不占空间， </span>
                <span>支持微信、支付宝、云闪付、手机银行等多种付款方式，</span>
                <span> 无任何硬件费用。 </span>
              </div>
            </div>
            <div class="card_item card_bg">
              <div class="top">
                <img src="../../../assets/img/home/detail/jhm2.png" alt="" />
              </div>
              <div class="center">
                <span>云播报</span>
                <span>云播报，实时播报收款信息， </span>
                <span>支持流量卡、WiFi等多种联网方式，设备声音洪亮，</span>
                <span> 帮助商家避免客户“伪支付”。 </span>
              </div>
            </div>
            <div class="card_item card_bg">
              <div class="top">
                <img src="../../../assets/img/home/detail/jhm3.png" alt="" />
              </div>
              <div class="center">
                <span>电签POS</span>
                <span>扫码枪被扫设备，支持与收银系统连接、 </span>
                <span>可独立收银、可生成动态收款码等多种收款形式，</span>
                <span>满足客户各类收款场景。</span>
              </div>
            </div>
          </div>
          <div class="btn" @click="handleApply(8)">立即咨询</div>
        </div>
      </div>
    </div>

    <div class="container container_flex">
      <div class="box">
        <div class="container_title">支付产品——批量收付/代收付</div>
        <div class="card_box">
          <div class="card_list">
            <div class="card_item">
              <div class="top">
                <img
                  src="../../../assets/img/home/detail/qianbao1.png"
                  alt=""
                />
              </div>
              <div class="center">
                <span>批量收付</span>
                <span
                  >客户可以自主选择接收资金的账户，不必在指定银行开户；
                </span>
                <span>客户可以自主选择微信、支付宝或云闪付（银行卡）</span>
                <span> 渠道完成主动付款。 </span>
              </div>
            </div>
            <div class="card_item">
              <div class="top">
                <img
                  src="../../../assets/img/home/detail/qianbao2.png"
                  alt=""
                />
              </div>
              <div class="center">
                <span>代收付</span>
                <span
                  >客户将自己的收款和付款账户信息均告知银行或支付机构，
                </span>
                <span>由其代为完成相应的收付款操作。</span>
              </div>
            </div>
          </div>
          <div class="btn" @click="handleApply(9)">立即咨询</div>
        </div>
      </div>
    </div>

    <!-- 一键办理弹出框 -->
    <el-dialog
      title="预留信息"
      :visible.sync="dialogVisible"
      width="25%"
      center
      :before-close="dialogColse"
    >
      <div class="dialog_head">
        <span>我们的客户经理将在1个工作日内与您联系！</span>
      </div>
      <div class="register_phone">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="10px"
          class="demo-ruleForm"
        >
          <el-form-item prop="reserveName">
            <el-input
              prefix-icon="el-icon-user"
              v-model="ruleForm.reserveName"
              placeholder="请输入称呼昵称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="reservePhone">
            <el-input
              prefix-icon="el-icon-mobile-phone"
              v-model="ruleForm.reservePhone"
              maxlength="11"
              placeholder="请输入联系方式"
            ></el-input>
          </el-form-item>
          <el-form-item prop="reserveEntName">
            <el-input
              prefix-icon="el-icon-office-building"
              v-model="ruleForm.reserveEntName"
              placeholder="请输入企业名称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="remark">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2 }"
              v-model="ruleForm.remark"
              placeholder="备注......(200字以内)"
              maxlength="200"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >提交预约</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span> -->
    </el-dialog>

    <el-dialog :visible.sync="dialogSuccess" width="25%" center>
      <div class="success_icon">
        <img src="../../../assets/img/success.png" alt="" />
      </div>
      <div class="success_title">提交成功</div>
      <div class="success_content">
        <p>随后工作人员将与您联系，请保持电话畅通。</p>
        <p>咨询热线：<span>400-666-3369</span></p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addReserve } from "../../../api/reserve";
export default {
  components: {},
  props: {},
  data() {
    return {
      dialogSuccess: false,
      dialogVisible: false,
      registerPhone: "",
      ruleForm: {
        reserveName: "",
        reservePhone: "",
        reserveEntName: "",
        remark: "",
      },
      rules: {
        reserveName: [
          { required: true, message: "请输入预约人名称", trigger: "blur" },
          { min: 2, max: 5, message: "长度在 2 到 5 个字符", trigger: "blur" },
        ],
        reservePhone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    // 体验信息提交
    handleApply(num) {
      this.dialogVisible = true;
      this.ruleForm.reserveType = num;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // this.ruleForm.reserveType = "27";
          addReserve(this.ruleForm).then((response) => {
            // if (response.code == 200) {
            //   // this.$modal.msgSuccess("意见办理注册预约成功");
            // }
            this.dialogSuccess = true;
            this.dialogVisible = false;
          });
        }
      });
    },
    dialogColse() {
      this.$refs.ruleForm.resetFields();
      this.dialogVisible = false;
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
.banner {
  width: 100%;
  height: 300px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.breadcrumb_box {
  width: 1200px;
  padding: 30px 0 0;
  margin: 0 auto;
}

.container {
  padding: 60px;
}

.container_bg {
  background: #f5f5f5;
  padding: 60px;
}

.container_flex {
  background: #f5f5f5;
}

.container_flex .card_list {
  display: flex;
  justify-content: center;
}

.container_flex .card_item {
  margin: 0 30px;
}

.box {
  width: 1200px;
  margin: 0 auto;
}

.container_title {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.zs_box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.zs_img {
  width: 340px;
  height: 250px;
}

.zs_img img {
  display: block;
  width: 100%;
  height: 100%;
}

.zs_title {
  font-size: 24px;
  color: #333;
  margin: 30px 0;
}

.zs_content {
  display: flex;
  width: 80%;
  font-size: 18px;
  color: #333;
  text-align: center;
}

.card_box {
  margin-top: 40px;
}

.card_list {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.card_item {
  width: 360px;
  height: 355px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: #fff;
}

.card_bg {
  border: 1px solid #cacaca;
}

.top {
  width: 165px;
  height: 165px;
}

.top img {
  display: block;
  width: 100%;
}

.center span {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 12px;
  font-size: 12px;
  color: #333;
}

.center span:first-child {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.btn {
  width: 132px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 28px;
  /* border: 1px solid #055afe; */
  background: #055afe;
  margin: 40px auto 0px;
  cursor: pointer;
  color: #fff;
}

.btn:hover {
  background: #055afe;
  color: #fff;
}

.el-form {
  text-align: center;
}
.dialog_head {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #055afe;
  margin-bottom: 20px;
}
.success_icon {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.success_icon img {
  display: block;
  width: 100%;
  height: 100%;
}

.success_title {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  margin: 30px 0;
}

.success_content {
  width: 100%;
  text-align: center;
  font-size: 18px;
  color: #919191;
}
.success_content p span {
  color: #055afe;
}
</style>
